import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';

const Faq = (props) => {
  return (
    <Layout fullMenu location={props.location} title="FAQ">
      <article id="main">
        <section className="wrapper style5 rsvp">
          <iframe
            title="hidden_iframe"
            name="hidden_iframe"
            id="hidden_iframe"
            style={{ display: 'none' }}
          ></iframe>

          <div className="inner">
            <div className="thanks">
              {/* <h2 class="faq-title">Frequently Asked Questions</h2> */}
              <br />
              <p className="bold">Q: Can I bring a date?</p>
              <p>A: Unfortunately, we are unable to provide plus ones for all of our guests due to space constraints. If you have a guest you'd like to bring, please let us know and we will try our best to accommodate to your wishes. We apologize for the inconvenience and hope you still plan to join us in celebrating our special day.</p>
              <p className="bold">Q: What should I wear?</p>
              <p>A: We recommend dressing in formal or semi-formal attire for the wedding ceremony and reception. However, feel free to express your personal style and wear something that makes you feel comfortable and confident. The most important thing is to be respectful and dressed appropriately for the occasion. We can't wait to see everyone's best fits!</p>
              <p className="bold">Q: Where should I park? Is it free?</p>
              <p>A: Celebrino provides their own free parking available at the event center. Carpooling is highly recommended to help conserve space for other guests. </p>
              <p className="bold">Q: Will your wedding be indoors or outdoors?</p>
              <p>A: Celebrino is an outdoor and indoor wedding venue. Our guests can enjoy a cocktail outside while having a great view of the sunset or take photos by the water fountains. There is a playground for children as well. We hope you enjoy the scenic space that Celebrino provides and we can't wait to see the photos!</p>
              <p className="bold">Q: Where can I upload or see the wedding photos?</p>
              <p>A: You may upload or view photos from the wedding <Link to="/photos">here</Link>. We hope you got our good side... :)</p>

            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default Faq;
